document.addEventListener('DOMContentLoaded', function () {
    const fileInputs = document.querySelectorAll('input[type="file"]');
    const nextButton = document.getElementById('nextButton');

    fileInputs.forEach(function (input) {
        input.addEventListener('change', function () {
            if (this.files.length > 0) {
                nextButton.removeAttribute('disabled');
            } else {
                nextButton.setAttribute('disabled', 'disabled');
            }

            localStorage.setItem('document', input.value);
        });
    });
});