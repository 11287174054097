document.addEventListener('DOMContentLoaded', function () {
    const quizCards = document.querySelectorAll('.chub-quiz-card');
    const nextButton = document.getElementById('nextButton');
    const selectedMedicationTime = document.getElementById('selectedMedicationTime');

    quizCards.forEach(function (quizCard) {
        quizCard.addEventListener('click', function () {

            quizCards.forEach(function (otherCard) {
                otherCard.classList.remove('active');
            });

            quizCard.classList.add('active');

            if (selectedMedicationTime)
                selectedMedicationTime.value = quizCard.querySelector('input[type=hidden]').value;

            const selectedCard = document.querySelector('.chub-quiz-card.active');
            if (selectedCard) {
                nextButton.removeAttribute('disabled');
            } else {
                nextButton.setAttribute('disabled', 'disabled');
            }
        });
    });
});
