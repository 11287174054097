document.addEventListener('DOMContentLoaded', function () {
    const paymentBlocks = document.querySelectorAll('.payment-block');
    const nextButton = document.getElementById('nextButton');

    function formatPrice(price) {
        const formattedPrice = price.toFixed(2);
        return formattedPrice.endsWith('.00') ? formattedPrice.slice(0, -3) : formattedPrice;
    }

    paymentBlocks.forEach(function (block) {
        const button = block.querySelector('.payment-type-btn');
        const dataPrice = block.querySelector('input');
        const subscriptionId = button.dataset.subscriptionId;
        const parent = dataPrice.closest('.payment-block');

        const paymentTotal = document.querySelector('.subscriptions-section .payments-total');
        button.addEventListener('click', function () {
            paymentBlocks.forEach(function (otherBlock) {
                otherBlock.querySelector('.payment-type-btn').classList.remove('active');
                otherBlock.querySelector('.payment-hidden-desc').style.display = 'none';
            });

            const total = document.querySelector('.payments-total');
            const priceElement = total.querySelector('.price');
            const salePriceElement = total.querySelector('.sale-price');

            const monthsCountSection = parent.querySelector('[data-months-count]');
            const priceSection = parent.querySelector('[data-price]');

            const price = dataPrice.value;
            const regularPrice = dataPrice.getAttribute('data-regular-price');
            const month = parseInt(dataPrice.getAttribute('data-month'));

            priceElement.innerText = '$' + formatPrice(regularPrice * month);
            salePriceElement.innerText = '$' + formatPrice(price * month);

            if (priceElement.innerText === salePriceElement.innerText) {
                priceElement.style.display = 'none';
            } else {
                priceElement.style.display = 'block';
            }

            monthsCountSection.innerText = month
            priceSection.innerText = formatPrice(price * month)

            localStorage.setItem('totalPrice', price * month);

            document.querySelector('input[name=subscription]').value = subscriptionId

            button.classList.add('active');
            block.querySelector('.payment-hidden-desc').style.display = 'block';
            paymentTotal.style.display = 'flex';

            if (button.classList.contains('active')) {
                nextButton.removeAttribute('disabled');
            } else {
                nextButton.setAttribute('disabled', 'disabled');
            }
        });
    });
});
