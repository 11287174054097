document.addEventListener('DOMContentLoaded', function () {
    // const optionCards = document.querySelectorAll('.option-card');
    //
    // optionCards.forEach(function (card) {
    //     const button = card.querySelector('.btn-green');
    //     const productTitle = card.querySelector('h3').textContent;
    //     const productDescription = card.querySelector('.product-description') ? card.querySelector('.product-description').textContent : null;
    //     const productPrice = parseFloat(button.textContent.match(/\$([\d.]+)/)[1]);
    //
    //     button.addEventListener('click', function () {
    //         const productInfo = {
    //             title: productTitle,
    //             description: productDescription,
    //             price: productPrice
    //         };
    //
    //         localStorage.setItem('selectedProduct', JSON.stringify(productInfo));
    //     });
    // });
});